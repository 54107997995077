import * as React from "react"
import {Helmet} from "react-helmet"
import StyledBackgroundSection from "../components/BackgroundSection"

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\""
}

// markup
const IndexPage = ({ data }) => {
  return (
    <div style={pageStyles}>
      <Helmet defer={false}>
        <title>Greene.Photos</title>
        <meta name="description" content="A wonderful showcase for Greene.Photos on #AnotherGreeneAdventure"></meta>
      </Helmet>
      <StyledBackgroundSection></StyledBackgroundSection>
    </div>
  )
}

export default IndexPage
