import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "images/DSC_8915.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        file(relativePath: { eq: "images/MG-Logo-Watermark-Glow.png" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }        
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={imageData}
      backgroundColor={`#040e18`}
    >
      <div className="headlineContainer">
          <div className="headlineWrapper">
            <div className="headlineLogoContainer">
              <Img
                  fluid={data.file.childImageSharp.fluid}
                  alt=""
              />  
            </div>
            <div className="headline">greene
              <span className="headline2">.photos</span>
            </div>
            <div className="subheadline">
                <span>#AnotherGreeneAdventure</span>
            </div>
          </div>
      </div>
    </BackgroundImage>
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
  height: 100vh;
  background-position: top center;
  background-repeat: repeat-y;
  background-size: cover;

  .headlineContainer {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .headlineContainer .headlineWrapper {
    margin: 0;        
    width: 80%;
    max-width: 1000px;
    text-align: center;
  }

  .headlineContainer .headlineWrapper .headlineLogoContainer {
    width: 40%;
    margin: auto;
    margin-bottom: 20px;
  }

  .headlineContainer .headlineWrapper .headline { 
    font-size: 12vw;
    line-height: 12vw;
    margin: -20px 0 0 0;
    text-shadow: 1px 1px 5px black;
    display: block;
    position: relative;
    width: 100%;
    letter-spacing: -2px;
    font-weight: 900;
    z-index: 100;
  }

  .headlineContainer .headlineWrapper .headline .headline2 {
   font-weight: 100; 
  }

  @media only screen and (min-width: 1000px) {
    .headlineContainer .headlineWrapper .headline { 
        font-size: 119px;
        line-height: 119px;
        margin-top: 0px;
    }

    .headlineContainer .headlineWrapper .headlineLogoContainer {
      margin-bottom: 0px;
    }    
  }

  .headlineContainer .headlineWrapper .subheadline { 
    background: rgba(0, 151, 19, 0.6); 
    font-size: 3.5vw;
    line-height: 4vw;

    text-shadow: 1px 1px black;
    display: block;
    position: relative;
    width: 100%;

    margin-top: 0px;
  }

  @media only screen and (min-width: 1000px) {
    .headlineContainer .headlineWrapper .subheadline { 
        background: rgba(0, 151, 19, 0.6); 
        font-size: 30px;
        line-height: 35px;
        width: 100%;
    }

    .headlineContainer .headlineWrapper .subheadline span { 
        font-size: 30px;
        line-height: 35px;
    }    
  }  
`

export default StyledBackgroundSection